import React, { useContext, useEffect, useState } from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from "gatsby"
import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";

export const typename = 'Set_Modules_InvestorCard'

const Wrap = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;

    &:hover {
        &::after {
            opacity: 1;
        }
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -2rem;
        left: -3rem;
        bottom: -2rem;
        right: 0;
        background-color: #2b2a27;
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.6, 0, 0.25, 1);
    }

    p, span {
        z-index: 1;
    }

    .image {
        position: relative;
        z-index: 1;
        margin-right: 2rem;
        width: 9rem;
        height: 9rem;
        background-color: var(--brand-light-gray);
        flex-shrink: 0;
    }
    .content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        p {
            font-size: 2rem;
            line-height: 1.38462em;
            font-weight: 300;
            margin-bottom: .3rem;
            &:first-child {
                margin-bottom: .5rem;
            }
        }
        .sector,
        .region {
            z-index: 1;
            font-size: 1.4rem;
            line-height: 1.28571em;
            font-weight: 200;
            color: var(--brand-light-gray);
            span {
              font-size: 1.4rem;
              font-weight: 400;
            }
        }
    }
`;

const InvestorCardBlock = ({
  investor,
  onClose
}) => {
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (investor.title.includes('Masayoshi Son')) {
      setDisable(true)
    }
  }, [])

  const clickInvestor = (slug) => {
    if (disable) return
    const regex = /^\/(?:([a-z]{2})(?=\/|$))?/
    const locale = window.location.pathname.match(regex)?.[1]
    navigate(`${locale ? '/' + locale : ''}/team?id=${slug}`, { replace: true });
    const url = new URL(window.location.href)
    if(url?.pathname.includes('/team')) {
        setTimeout(() => {
        const div = document.getElementById(slug)
        window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: div.offsetTop - (window.innerWidth < 1024 ? 100 : 250),
        })
        }, 100)
        onClose();
    }
  }

  return (
    <Wrap
      role="none"
      onClick={() => clickInvestor(investor.slug)}
      className={`${disable ? '' : 'cursor-pointer'}`}
    >
      <div className={'image'}>
        {investor.preview_image?.imageFile && (
            <GatsbyImage loading="eager" image={getImage(investor.preview_image.imageFile)} alt={investor.preview_image.alt ? investor.preview_image.alt : ''} />
        )}
      </div>

      <div className="content">
        <p className="p">{investor.title}</p>
        {investor?.sectors?.length > 0 && (
          <span className="sector">
            <span className="font-medium">Sector: </span>
            {investor.sectors.map(
              (s, index) => `${index > 0 ? ', ' : ''}${s.title}`,
            )}
          </span>
        )}
        {investor?.region?.length > 0 && (
          <span className="region">
            <span className="font-medium">Country: </span>
            {investor.region.map(
              (s, index) => `${index > 0 ? ', ' : ''}${s.title}`,
            )}
          </span>
        )}
      </div>
    </Wrap>
  )
}
export default InvestorCardBlock
