import styled, { css, createGlobalStyle } from "styled-components";
import breakpoints from "../../utils/breakpoints";

//MagazineGrotesque
import MagazineGrotesqueThinEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Thin.eot";
import MagazineGrotesqueThinWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Thin.woff";
import MagazineGrotesqueThinWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Thin.woff2";

import MagazineGrotesqueExLightEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-ExLight.eot";
import MagazineGrotesqueExLightWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-ExLight.woff";
import MagazineGrotesqueExLightWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-ExLight.woff2";

import MagazineGrotesqueLightEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Light.eot";
import MagazineGrotesqueLightWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Light.woff";
import MagazineGrotesqueLightWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Light.woff2";

import MagazineGrotesqueRegularEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Regular.eot";
import MagazineGrotesqueRegularWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Regular.woff";
import MagazineGrotesqueRegularWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Regular.woff2";

import MagazineGrotesqueMediumEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Regular.eot";
import MagazineGrotesqueMediumWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Regular.woff";
import MagazineGrotesqueMediumWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Regular.woff2";

import MagazineGrotesqueSemiBoldEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-SemiBold.eot";
import MagazineGrotesqueSemiBoldWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-SemiBold.woff";
import MagazineGrotesqueSemiBoldWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-SemiBold.woff2";

import MagazineGrotesqueBoldEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Bold.eot";
import MagazineGrotesqueBoldWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Bold.woff";
import MagazineGrotesqueBoldWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Bold.woff2";

import MagazineGrotesqueHeavyEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Heavy.eot";
import MagazineGrotesqueHeavyWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Heavy.woff";
import MagazineGrotesqueHeavyWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Heavy.woff2";

import MagazineGrotesqueBlackEot from "../../fonts/MagazineGrotesque/MagazineGrotesque-Black.eot";
import MagazineGrotesqueBlackWoff from "../../fonts/MagazineGrotesque/MagazineGrotesque-Black.woff";
import MagazineGrotesqueBlackWoff2 from "../../fonts/MagazineGrotesque/MagazineGrotesque-Black.woff2";

//PostGrotesque
import PostGroteskThinEot from "../../fonts/PostGrotesk/PostGrotesk-Thin.eot";
import PostGroteskThinWoff from "../../fonts/PostGrotesk/PostGrotesk-Thin.woff";
import PostGroteskThinWoff2 from "../../fonts/PostGrotesk/PostGrotesk-Thin.woff2";

import PostGroteskLightEot from "../../fonts/PostGrotesk/PostGrotesk-Light.eot";
import PostGroteskLightWoff from "../../fonts/PostGrotesk/PostGrotesk-Light.woff";
import PostGroteskLightWoff2 from "../../fonts/PostGrotesk/PostGrotesk-Light.woff2";

import PostGroteskBookEot from "../../fonts/PostGrotesk/PostGrotesk-Book.eot";
import PostGroteskBookWoff from "../../fonts/PostGrotesk/PostGrotesk-Book.woff";
import PostGroteskBookWoff2 from "../../fonts/PostGrotesk/PostGrotesk-Book.woff2";

import PostGroteskMediumEot from "../../fonts/PostGrotesk/PostGrotesk-Medium.eot";
import PostGroteskMediumWoff from "../../fonts/PostGrotesk/PostGrotesk-Medium.woff";
import PostGroteskMediumWoff2 from "../../fonts/PostGrotesk/PostGrotesk-Medium.woff2";

import PostGroteskBoldEot from "../../fonts/PostGrotesk/PostGrotesk-Bold.eot";
import PostGroteskBoldWoff from "../../fonts/PostGrotesk/PostGrotesk-Bold.woff";
import PostGroteskBoldWoff2 from "../../fonts/PostGrotesk/PostGrotesk-Bold.woff2";

import PostGroteskBlackEot from "../../fonts/PostGrotesk/PostGrotesk-Black.eot";
import PostGroteskBlackWoff from "../../fonts/PostGrotesk/PostGrotesk-Black.woff";
import PostGroteskBlackWoff2 from "../../fonts/PostGrotesk/PostGrotesk-Black.woff2";

export const FontFaces = createGlobalStyle`
  @font-face {
      font-family: 'Magazine Grotesque';
      src: url(${MagazineGrotesqueThinWoff2}) format('woff2'),
      url(${MagazineGrotesqueThinWoff}) format('woff'),
      url(${MagazineGrotesqueThinEot}) format('eot');
      font-weight: 100;
      font-style: normal;
			font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueExLightWoff2}) format('woff2'),
    url(${MagazineGrotesqueExLightWoff}) format('woff'),
    url(${MagazineGrotesqueExLightEot}) format('eot');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueLightWoff2}) format('woff2'),
    url(${MagazineGrotesqueLightWoff}) format('woff'),
    url(${MagazineGrotesqueLightEot}) format('eot');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueRegularWoff2}) format('woff2'),
    url(${MagazineGrotesqueRegularWoff}) format('woff'),
    url(${MagazineGrotesqueRegularEot}) format('eot');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueMediumWoff2}) format('woff2'),
    url(${MagazineGrotesqueMediumWoff}) format('woff'),
    url(${MagazineGrotesqueMediumEot}) format('eot');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueSemiBoldWoff2}) format('woff2'),
    url(${MagazineGrotesqueSemiBoldWoff}) format('woff'),
    url(${MagazineGrotesqueSemiBoldEot}) format('eot');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueBoldWoff2}) format('woff2'),
    url(${MagazineGrotesqueBoldWoff}) format('woff'),
    url(${MagazineGrotesqueBoldEot}) format('eot');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueBlackWoff2}) format('woff2'),
    url(${MagazineGrotesqueBlackWoff}) format('woff'),
    url(${MagazineGrotesqueBlackEot}) format('eot');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Magazine Grotesque';
    src: url(${MagazineGrotesqueHeavyWoff2}) format('woff2'),
    url(${MagazineGrotesqueHeavyWoff}) format('woff'),
    url(${MagazineGrotesqueHeavyEot}) format('eot');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Post Grotesk';
    src: url(${PostGroteskThinWoff2}) format('woff2'),
    url(${PostGroteskThinWoff}) format('woff'),
    url(${PostGroteskThinEot}) format('eot');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Post Grotesk';
    src: url(${PostGroteskLightWoff2}) format('woff2'),
    url(${PostGroteskLightWoff}) format('woff'),
    url(${PostGroteskLightEot}) format('eot');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Post Grotesk';
    src: url(${PostGroteskBookWoff2}) format('woff2'),
    url(${PostGroteskBookWoff}) format('woff'),
    url(${PostGroteskBookEot}) format('eot');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Post Grotesk';
    src: url(${PostGroteskMediumWoff2}) format('woff2'),
    url(${PostGroteskMediumWoff}) format('woff'),
    url(${PostGroteskMediumEot}) format('eot');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Post Grotesk';
    src: url(${PostGroteskBoldWoff2}) format('woff2'),
    url(${PostGroteskBoldWoff}) format('woff'),
    url(${PostGroteskBoldEot}) format('eot');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Post Grotesk';
    src: url(${PostGroteskBlackWoff2}) format('woff2'),
    url(${PostGroteskBlackWoff}) format('woff'),
    url(${PostGroteskBlackEot}) format('eot');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
`;

const Heading = css`
  font-weight: 500;
  font-family: var(--font-secondary);
  margin: 0;
  font-size: 3.2rem;
  line-height: 1.125;
  letter-spacing: 0;
`;

export const Mega = styled.p`
  ${Heading}

  @media ${breakpoints.tabletSm} {
    font-size: 7.2rem;
    line-height: 1.3055555556;
  }

  @media ${breakpoints.desktop} {
    font-size: 9rem;
    line-height: 1.0444444444;
  }
`;

export const H1 = styled.h1`
  ${Heading}
  max-width: 16em;

  @media ${breakpoints.tabletSm} {
    font-size: 5.4rem;
    line-height: 1.0909090909;
  }

  @media ${breakpoints.tablet} {
    font-size: 7.4rem;
    line-height: 1.0740740741;
  }
`;

export const H2 = styled.h2`
  ${Heading};

  @media ${breakpoints.tablet} {
    font-size: 3.8rem;
    line-height: 1.0909090909;
  }
`;

// export const H3 = styled.h3`
//   ${Heading};

//   @media ${breakpoints.tabletSm} {
//     font-size: 4.4rem;
//     line-height: 1.0909090909;
//   }

//   @media ${breakpoints.tablet} {
//     font-size: 3.8rem;
//     line-height: 1.1578947368;
//   }
// `;

export const H4 = styled.h3`
  ${Heading};

  @media ${breakpoints.tabletSm} {
    font-size: 3.5rem;
    line-height: 1.0909090909;
  }

  @media ${breakpoints.tablet} {
    font-size: 4.4rem;
    line-height: 1.1363636364;
  }

  @media ${breakpoints.desktopSm} {
    font-size: 5.4rem;
    line-height: 1.0714285714;
  }
`;

export const H5 = styled.h3`
  ${Heading};

  @media ${breakpoints.tabletSm} {
    font-size: 3.8rem;
    line-height: 1.1578947368;
  }
`;

export const Metadata = styled.span`
  display: block;
  font-size: 1.2rem;
  font-weight: 500;

  @media ${breakpoints.desktopSm} {
    font-size: 1.6rem;
  }
`;

export const P = styled.p`
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 500;
`;
