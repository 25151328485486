const sizes = {
  mobile: "375px",
  tabletSm: "767px",
  tablet: "1024px",
  tabletLg: "1100px",
  desktopSm: "1280px",
  desktop: "1440px",
  monitor: "1680px",
};

export default {
  mobile: `(min-width: ${sizes.mobile})`,
  tabletSm: `(min-width: ${sizes.tabletSm})`,
  tablet: `(min-width: ${sizes.tablet})`,
  tabletLg: `(min-width: ${sizes.tabletLg})`,
  desktopSm: `(min-width: ${sizes.desktopSm})`,
  desktop: `(min-width: ${sizes.desktop})`,
  monitor: `(min-width: ${sizes.monitor})`,
};
