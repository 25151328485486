import React from "react";
import styled from "styled-components";
import Arrow from "../../assets/vectors/icon-arrow-small.svg";

const TextContainer = styled.span`
  position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
  z-index: 2;
  transition: all 0.3s ease-in-out;
`;

const IconContainer = styled.span`
  position: relative;
  margin-left: 1.2rem;
  transition: all 0.3s ease-in-out;
  z-index: 2;

  svg {
    width: 1.4rem;
    height: auto;
    display: block;
    fill: currentColor;
  }
`;

const HoverIconContainer = styled.span`
  position: relative;
  width: 0rem;
  transform: translateX(-5rem);
  transition: all 0.3s ease-in-out;
  z-index: 2;

  svg {
    width: 1.4rem;
    height: auto;
    display: block;
    fill: currentColor;
  }
`;

const Container = styled.div`
  font-family: var(--font-secondary);
  display: inline-flex;
  align-items: center;
  background-color: var(--brand-gray);
  border-radius: 0rem;
  text-align: center;
  color: var(--brand-light-gray);
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 6rem;
  padding: 0 2.5rem;
  transition: background-color 0.5s var(--ease-soft);
  text-decoration: none;
  font-weight: 500;
  backface-visibility: hidden;
  transition: background-color 0.18s ease-in-out,
    box-shadow 0.18s ease-in-out;
	max-width: 100%;
	overflow: hidden;

  &:hover {
    ${IconContainer} {
      transform: translateX(5rem);
      transition: all 0.3s ease-in-out;
      svg {
        fill: var(--brand-white);
      }
    }
    ${TextContainer} {
      transform: translateX(2.6rem);
      transition: all 0.3s ease-in-out;
      text-align: right;
    }
    ${HoverIconContainer} {
      transform: translateX(0rem);
      transition: all 0.3s ease-in-out;
      svg {
        fill: var(--brand-white);
      }
    }
  }

  &:focus {
    border-color: var(--brand-blue);
    background-color: var(--brand-blue);
    color: var(--brand-white);

    ${IconContainer} svg,
    ${HoverIconContainer} svg {
      fill: var(--brand-white);
    }
  }

  &[target="_blank"] {
    ${IconContainer},
    ${HoverIconContainer} {
      transform: rotate(-45deg);
    }
  }

  &[data-inverse="true"] {
    background-color: var(--brand-teal);
    color: var(--brand-gray);

    ${IconContainer},
    ${HoverIconContainer} {
      svg {
        fill: currentColor;
      }
    }

    &:hover {
      color: var(--brand-gray);
    }
  }

  &[data-theme="hover-alt"] {
    &:hover,
    &:focus {
      background-color: var(--brand-white);
      color: var(--brand-gray);

      ${TextContainer} {
        color: var(--brand-gray);
      }

      ${IconContainer},
      ${HoverIconContainer}{
        color: var(--brand-gray);
        svg {
          fill: currentColor;
        }
      }
    }

    &:hover{

    }

    &:focus {
      box-shadow: inset 0 0 0 0.2rem var(--brand-muted-blue);
    }
  }

  &[data-theme="outline"] {
    background-color: transparent;
    color: #FFF;
    border: 1px solid #FFF;

    &::before,
    &::after {
      width: 0;
      height: 100%;
      opacity: 0;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      background-image: linear-gradient(
        90deg,
        #E91711,
        transparent
      );
      background-image: linear-gradient(90deg, #E91711 5.69%, #EC760D 67.56%, transparent 100%);
      transition: width 0.6s cubic-bezier(0.6, 0, 0.25, 1),
        opacity 0.6s cubic-bezier(0.72, 0.16, 0.345, 0.875);
    }
    &::before {
      width: 100%;
      background-image: linear-gradient(90deg, #E91711 5.69%, #EC760D 67.56%, #EC760D 67.57%, #EFD608 130.75%);
      transition: width 0.6s cubic-bezier(0.6, 0, 0.25, 1),
        opacity 0.6s cubic-bezier(0.72, 0.16, 0.345, 0.875);
    }

    ${IconContainer},
    ${HoverIconContainer} {
      svg {
        fill: currentColor;
      }
    }
    ${TextContainer} {
      text-align:left;
      min-width: 10rem;
      padding-left:0rem;
      padding-right:6rem;
    }

    &:hover {
      background-color: transparent;
      box-shadow: inset 0 0 0 0.1rem var(--brand-blue);
      color: var(--brand-white);
  
      &::before {
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 1;
      }
      &::after {
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 1;
      }

      ${TextContainer} {
        text-align:right;
        padding-left:6rem;
        padding-right:0rem;
      }
    }
  }
`;

export default function CTA(props) {
  const { children, inverse = false, icon = true, ...restProps } = props;

  return (
    <Container data-inverse={inverse} {...restProps}>
      {icon && (
        <HoverIconContainer>
          <Arrow />
        </HoverIconContainer>
      )}
      <TextContainer>{children}</TextContainer>
      {icon && (
        <IconContainer>
          <Arrow />
        </IconContainer>
      )}
    </Container>
  );
}
