import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import parse from "html-react-parser";
import breakpoints from '../../utils/breakpoints';

import CTA from '../cta';

const Container = styled.div`
  position: fixed;
  z-index: var(--z-cookie-banner);
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 2.5rem var(--grid-padding);
  transform: ${props => props.active ? 'none' : 'translateY(100%)'};
  transition: transform 0.6s ease-in-out;
  will-change: transform;
  color: #262626;

  @media ${breakpoints.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;

    @media ${breakpoints.tablet} {
      margin-right: 3.5rem;
      max-width: 45em;
    }
  }

  button {
    position: relative:
    flex: none;
    margin-top: 1.5rem;
    z-index: 999;

    @media ${breakpoints.tablet} {
      margin-top: 0;
    }
  }

  a {
    color: #3A797E;
    text-decoration: underline;
  }
`;

export default function CookieBanner() {
  const [isActive, setIsActive] = useState(false);

  const data = useStaticQuery(graphql`
    query cookie {
      cms {
        globalSet(handle: "footer") {
          ... on cms_GlobalSet_Footer {
            cookie_message
          }
        }
      }
    }
  `)

  const { cookie_message } = data.cms.globalSet;

  const onCTAClick = () => {
    localStorage.setItem('SBCookieBanner', '1');
    setIsActive(false)
  }

  useEffect(() => {
    if (localStorage.getItem('SBCookieBanner')) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, []);

  return (
    <Container active={isActive}>
      {parse(cookie_message || '')}
      <CTA as="button" theme="dark" onClick={onCTAClick}>Close</CTA>
    </Container>
  )
}