import React from 'react'
import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";

import IconCaretDown from '../../../assets/vectors/icon-caret-down.svg'
import IconPlus from '../../../assets/vectors/icon-plus.svg'
import IconX from '../../../assets/vectors/icon-x.svg'
import Arrow from '../../../assets/vectors/icon-arrow-small.svg'

const Background = styled.span`
display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--brand-light-gray);
  transition: background-color 0.4s cubic-bezier(0.72, 0.16, 0.345, 0.875);
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    background-image: linear-gradient(0deg, var(--brand-light-gray), transparent);
    opacity: 0;
    transform-origin: bottom center;
    transition: opacity 0.7s cubic-bezier(0.72, 0.16, 0.345, 0.875);
  }
`;

const Container = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.72, 0.16, 0.345, 0.875);

  svg {
    width: 1.2rem;
    height: 100%;
    fill: var(--brand-grey);
    z-index: 1;
    path {
      fill: inherit;
    }
  }
  
  &.large {
    width: 5rem;
    height: 5rem;

    .background {
      background-color: var(--brand-light-gray);
    }
  }

  &:hover {
    .background {
      background-color: var(--brand-light-gray);
      &::after {
        opacity: 1;
      }
    }
  }

  &.outline {
    width: 5rem;
    height: 5rem;
    color: #FFF;
    font-size: 5rem;

    @media ${breakpoints.desktop} {
      width: 8rem;
      height: 8rem;
      font-size: 8rem;
    }

    ${Background} {
      background-color: transparent;
      border: 1px solid #FFF;
    }
    svg {
      width: 2.4rem;
      fill: #FFF;
    }
    &:hover {
      color: var(--brand-gray);
      svg {
        fill: var(--brand-gray);
      }
      ${Background} {
        background-color: var(--brand-light-gray);
      } 
    }
  }

  &.outline-dark {
    width: 5rem;
    height: 5rem;
    color: #FFF;
    font-size: 5rem;

    @media ${breakpoints.desktop} {
      width: 6rem;
      height: 6rem;
      font-size: 6rem;
    }

    ${Background} {
      background-color: transparent;
      border: 1px solid var(--brand-medium-gray);
    }
    svg {
      width: 30%;
      fill: #FFF;
    }
    &:hover {
      svg {
        fill: var(--brand-medium-gray);
      }
      ${Background} {
        background-color: var(--brand-light-gray);
      } 
    }
  }
`;

export default function RoundButtonBlock(props) {
  const { children, name = '', large = false, theme = '', ...restProps } = props;
  const iconTypes = {
    caretDown: <IconCaretDown />,
    plus: <IconPlus />,
    close: <IconX />,
    arrow: <Arrow />,
  }
  const icon = iconTypes[name]

  return (
    <Container {...restProps} className={`${theme ? theme : ''}`}>
      <Background />
      {icon}
    </Container>
  );
}
