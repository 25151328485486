import { withPrefix } from "gatsby";

export const grid = (cols, trailing = false) => {
  let trail = "0px";

  if (!trailing) {
    trail = "var(--grid-gutter)";
  }

  return `calc((var(--grid-width) + var(--grid-gutter) - var(--grid-padding)*2)/var(--grid-column-count)*${cols} - ${trail})`;
};

export const mapCompanySlugToLogo = companySlug => {
  let formatted = companySlug;

  if (formatted.indexOf("-com") > -1) {
    formatted = companySlug.replace("-com", "--");
  }

  if (formatted.indexOf("-2") > -1) {
    formatted = companySlug.replace("-2", "--");
  }

  if (formatted.indexOf("-3") > -1) {
    formatted = companySlug.replace("-3", "--");
  }

  const doubleDashIndex = formatted.indexOf("--");

  if (doubleDashIndex > -1) {
    formatted = formatted.substring(0, doubleDashIndex);
  }

  return withPrefix(`logos/logo-${formatted}`);
};

export const getLabelForNewsType = type => {
  switch (type) {
    case "video":
      return "Watch the Video";
    case "article":
      return "Read the Article";
    case "audio_podcast":
      return "Listen to the Podcast";
    default:
      return "";
  }
};
