import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import breakpoints from "../../utils/breakpoints";
import Results, { TEAM_TYPENAME } from '../../components/search/results'
import SearchIcon from '../../assets/vectors/icon-search.svg'

const SearchWrap = styled.div`
  .default {
    border-top: 0.1rem solid var(--brand-medium-gray);
    border-bottom: 0.1rem solid var(--brand-medium-gray);
    display: flex;
    width: 100%;
    align-items: center;
    height: 6rem;


    @media ${breakpoints.tabletSm} {
      padding: 2.5rem 0;
      height: auto;
    }
    @media ${breakpoints.tablet} {
      padding: 4rem 0;
    }
    svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 2.5rem;
      fill: #fff;
      @media ${breakpoints.tablet} {
        width: 2.2rem;
        height: 2.2rem;
        fill: var(--brand-medium-gray);
      }
    }
    .text {
      color: var(--brand-medium-gray);
      font-size: 1.2rem;
      height: 3rem;
      display: flex;
      align-items: center;
      @media ${breakpoints.tablet} {
        display:none;
        font-size: 3rem;
      }
    }
  }
  input {
    border: none;
    margin-bottom: 0;
    width: calc(100% - 8rem);
    height: 3rem;
    padding: 0;
    outline: none;
    background: transparent;
    backface-visibility: hidden;
    color: #FFF;
    font-size: 2rem;
    line-height: 1em;
    font-weight: 300;
    @media ${breakpoints.tablet} {
      font-size: 3rem;
      height: 5rem;
      padding-top: 0;
      width: calc(100% - 15rem);
    }
  }

  .status-wrap {
    width: 100%;

    .status {
      display: none;
      margin-top: 2rem;
      margin-bottom: 6.25vw;
      color: var(--brand-medium-gray);
      &.active {
        display:block;
      }
      @media ${breakpoints.tabletSm} {
        margin-bottom: 2.60417vw;
      }
      @media ${breakpoints.tablet} {
        display: block;
        margin-top: 3.5rem;
        margin-bottom: 5.20833vw;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: var(--brand-medium-gray);
        margin-right: 1rem;
      }
    }
  }

  .noResults {
    padding: 5.20833vw 0;
    .suggestions {
      display:block;
      padding-top: 0;
      margin-top: 5.20833vw;
      li {
        opacity: 1;
        margin-bottom: 2rem;
      }
    }
  }
`;

const Search = ({ seo, onActive, onClose, search, team }) => {
  const data = useStaticQuery(graphql`
    query globalsearch {
      cms {
        search: globalSet(handle: "search") {
          ... on cms_GlobalSet_Search {
            handle
            results_text
            no_results_subtext
            no_results_text
            companies_headline
            investors_headline
            search_input_field
            suggested_search_terms {
              name
            }
            site {
              handle
              name
            }
          }
        },
        team: entries(collection: "team", limit: 3000) {
          to
          data {
            id
            ... on cms_Entry_Team_Team {
              __typename
              id
              title
              published
              site {
                short_locale
                locale
                handle
                name

              }
              slug
              preview_image {
                ... on cms_Asset_Assets {
                  id
                  alt
                  imageFile {
                    publicURL
                    extension
                    childImageSharp {
                      gatsbyImageData(width: 90, height: 90, formats: [AUTO, WEBP, AVIF])
                    }
                    absolutePath
                    atime
                    base
                  }
                  path
                }
              }
              sectors {
                ... on cms_Term_Sectors_Sectors {
                  id
                  title
                }
              }
              region {
                id
                ... on cms_Term_Region_Region {
                  id
                  title
                }
              }
            }
          }
          total
          from
          has_more_pages
        }
        companies: entries(collection: "companies", limit: 3000) {
          to
          data {
            id
            ... on cms_Entry_Companies_Companies {
              __typename
              id
              title
              slug
              site {
                short_locale
                locale
                handle
                name

              }
              sectors {
                title
              }
              region {
                title
              }
              team {
                team_member {
                  title
                }
                hide_on_portfolio_page
              }
            }
          }
          total
          from
          has_more_pages
        }
      }
    }
  `);
  const globals = data.cms; 
  const teamItems = team?.data || globals.team.data
  const companyItems = globals.companies.data || []
  const searchdata = [...teamItems, ...companyItems]
  const suggestedTerms = search?.suggested_search_terms || globals?.search?.suggested_search_terms
  const results_text = search?.results_text || globals?.search?.results_text
  const no_results_text = search?.no_results_text || globals?.search?.no_results_text
  const no_results_subtext = search?.no_results_subtext || globals?.search?.no_results_subtext
  const investors_headline = search?.investors_headline || globals?.search?.investors_headline
  const companies_headline = search?.companies_headline || globals?.search?.companies_headline
  const search_input_field = search?.search_input_field || globals?.search?.search_input_field
  const [active, setActive] = useState(false)
  const [results, setResults] = useState([])
  const [noResults, setFallbackView] = useState(false)
  const [input, setInput] = useState('')
  const [searchData, setSearchData] = useState([])
  const emailInputRef = React.useRef(null)

  useEffect(() => {
    if (window.innerWidth > 1023) {
      //setActive(true)
    }
    document.body.style.overflow = 'hidden'
    if (emailInputRef.current) {
      emailInputRef.current.focus()
    }

    const loadData = async () => {
      const activeLocale = seo?.translations.find(
        (e) => e.is_current,
      ).site
      const filterDataByLang = searchdata.filter(
        (c) => c.site.handle === activeLocale,
      )
      setSearchData(filterDataByLang)
    }
    // fetch file
    loadData()
    return () => {
      document.body.style.overflow = ''
    }
  }, [active])

  //  user wants to search and clicks
  const activateSearch = () => {
    if (active) return
    setActive(true)
    onActive()
  }

  const onInputChange = (e) => {
    const { value } = e?.target
    setInput(value)
    let filtered = searchData.filter(
      (entry) =>
        entry.title.toLowerCase().includes(value.toLowerCase()) ||
        entry?.sectors?.title?.toLowerCase().includes(value.toLowerCase()) ||
        entry?.region?.title?.toLowerCase().includes(value.toLowerCase()) /*||
        entry?.sectors?.find((s) =>
          s.title.toLowerCase().includes(value.toLowerCase()),
        )*/ ||
        (entry.__typename === TEAM_TYPENAME &&
          entry?.region?.find((s) =>
            s.title.toLowerCase().includes(value.toLowerCase()),
          ))
    )

    //  -----------------------------------------------------------------------
    //  Check for associated companies for member
    const companiesForMembers = []
    filtered.forEach((member) => {
      if (member.__typename === TEAM_TYPENAME) {
        const { title } = member
        searchData.forEach((f) =>
          f?.team?.forEach(
            (x) =>
              x?.team_member?.title === title && companiesForMembers.push(f),
          ),
        )
      }
    })
    if (companiesForMembers && companiesForMembers.length > 0) {
      filtered = [...filtered, ...companiesForMembers]
    }
    //  -----------------------------------------------------------------------
    //  -----------------------------------------------------------------------
    //  Check if company has associated team members and add them
    const members = []
    filtered.forEach((f) =>
      f?.team?.forEach(
        (x) => x?.team_member?.title && members.push(x?.team_member?.title),
      ),
    )
    const associatedTeamMembers = searchData.filter((entry) =>
      members.find((x) => x === entry.title),
    )
    if (associatedTeamMembers && associatedTeamMembers.length > 0) {
      filtered = [...filtered, ...associatedTeamMembers]
    }
    //  -----------------------------------------------------------------------
    filtered = Array.from(new Set(filtered))
    setResults([...filtered])
    setFallbackView(filtered.length < 1)
  }

  return (
    <>
      <SearchWrap>
        <div className={"default"} onClick={activateSearch} role="none">
          <SearchIcon />
          {active ? (
            <input
              ref={emailInputRef}
              onChange={(e) => onInputChange(e)}
              type="text"
              name="search_input"
              value={input}
              className="h3 text-brand-light"
            />
          ) : (
            <span className={"text"}>{search_input_field}</span>
          )}
        </div>
        <div className="status-wrap">
          <div className={`status ${active ? 'active' : ''}`}>
            {results !== undefined && input !== ''
              ? `${results.length} ${results_text}`
              : search_input_field}
          </div>
        </div>
        {noResults && (
          <div className={"noResults"}>
            <p className="copy--large font-medium text-grey-eyebrow">
              {no_results_text}
            </p>
            <p className="copy--large font-light text-grey-eyebrow">
              {no_results_subtext}
            </p>
            {suggestedTerms.length > 0 && (
              <ul className={"suggestions"}>
                {suggestedTerms.map((e) => (
                  <li key={e.name}>
                    <button
                      type="button"
                      onClick={() => onInputChange({ target: { value: e.name } })}
                      className="h3 text-brand-light"
                    >
                      {e.name}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {results?.length > 0 && <Results globals={{ investors_headline, companies_headline }} results={results} onClose={onClose}/>}
      </SearchWrap>
    </>
  )
}
export default Search
