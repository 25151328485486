import React, { useContext, useEffect, useState } from 'react'
import styled from "styled-components";
import breakpoints from "../../../utils/breakpoints";
import CompanyCard from '../company_card'
import InvestorCard from '../investor_card'

export const typename = 'Set_Modules_Results'

export const TEAM_TYPENAME = 'cms_Entry_Team_Team'
export const PORTFOLIO_TYPENAME = 'cms_Entry_Companies_Companies'

const ResultsWrap = styled.div`
    position: relative;
    height: 100%;
    width: 100%;

    .results {
        position: relative;
        display: grid;
        width: 100%;
        grid-template-columns: repeat(12,minmax(0,1fr));

        @media ${breakpoints.tablet} {
            border-bottom: none;
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: 0;
              bottom: 0;
              width: 1px;
              background: var(--brand-medium-gray);
            }
        }

        &__item {
            cursor: pointer;
            font-size: 1em;
            position: relative;
            padding: 0.7rem 0 1.7rem;
            color: var(--brand-medium-gray);
            border-bottom: 0.1rem solid var(--brand-medium-gray);

            justify-content: space-between;
            text-align:center;
            align-items: center;
            display: block;
            grid-column: span 6/span 6;

            @media ${breakpoints.tablet} {
              display: flex;
              &:first-child {

                  padding-right: 8.33333%;
                  padding-left: 0;
              }
              border-bottom: 0;
              padding-bottom: 0;
              padding-top: 0;
              padding-left: 8.33333%;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -0.1rem;
                left: 0;
                width: 100%;
                height: 0.3rem;
                background-color: var(--brand-medium-gray);
                opacity: 0;
                transition: opacity 0.5s cubic-bezier(0.6, 0, 0.25, 1);
                @media ${breakpoints.tablet} {
                    content: none;
                }
            }
            &.active {
                color: var(--brand-light-gray);
                &::after {
                    opacity: 1;
                }
                @media ${breakpoints.tablet} {
                    color: var(--brand-light-gray);
                }
            }
            sup {
              top: 0;
              vertical-align: super;
              display: inline-block;
              font-size: 1rem;
              line-height: 3.6;

              @media ${breakpoints.tablet} {
                  line-height: 1;
              }
            }
        }
    }
    .resultsWrapper {
        display: block;
        padding-top: 9.375vw;
        grid-template-columns: repeat(12,minmax(0,1fr));
        grid-column: span 12/span 12;

        @media ${breakpoints.tabletSm} {
            padding-top: 3.90625vw;
        }
        @media ${breakpoints.tablet} {
          display: grid;
          padding-top: 4.16667vw;
        }
        
        .companyList {
          display: block;
          li {
            margin-bottom: 4rem;
            opacity: 1;

          }
          @media ${breakpoints.tablet} {
            display:none;
          }
        }

        .column {
          display: none;
          grid-column: span 6/span 6;
          &:first-of-type {
            padding-right: 8.33333%;
            padding-left: 0;
          }
          &.active {
            display:block;
          }

          @media ${breakpoints.tablet} {
            padding-left: 8.33333%;
            display:block;
          }
          ul{
            display:block;
            padding:0;
            li{
              opacity: 1;
              margin-bottom: 4rem;
            }
          }
        }
    }
`;

const ResultsBlock = ({ results, onClose, globals }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [team, setTeam] = useState([])
  const [portfolio, setPortfolio] = useState([])

  useEffect(() => {
    const extractedTeam = results.filter((t) => t.__typename === TEAM_TYPENAME)
    setTeam([...extractedTeam])
    const extractedPortfolio = results.filter(
      (pf) => pf.__typename === PORTFOLIO_TYPENAME,
    )
    setPortfolio([...extractedPortfolio])
  }, [results])

  return (
    <ResultsWrap>
      <div
        className={`results`}
      >
        <span
          role="none"
          onClick={() => setActiveTab(0)}
          className={`results__item ${activeTab === 0 ? 'active' : ''}`}
        >
          {globals.companies_headline}
          <sup className="text--tiny">{portfolio.length}</sup>
        </span>
        <span
          role="none"
          onClick={() => setActiveTab(1)}
          className={`results__item ${activeTab === 1 ? 'active' : ''}`}
        >
          {globals.investors_headline}
          <sup className="text--tiny">{team.length}</sup>
        </span>
        <div
          className={`resultsWrapper`}
        >
          <ul className={`companyList`}>
            {activeTab === 0 ? (
              <>
                {portfolio.map((t) => (
                  <li key={t.id}>
                    <CompanyCard company={t} onClose={onClose}/>
                  </li>
                ))}
              </>
            ) : (
              team.map((t) => (
                <li key={t.id}>
                  <InvestorCard investor={t} onClose={onClose}/>
                </li>
              ))
            )}
          </ul>
          <div className={`column`}>
            <ul className={`companyList`}>
              {portfolio
                .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
                .map((t) => (
                  <li key={t.id}>
                    <CompanyCard company={t} onClose={onClose}/>
                  </li>
                ))}
            </ul>
          </div>
          <div className={`column`}>
            <ul className={`companyList`}>
              {team
                .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
                .map((t) => (
                  <li key={t.id}>
                    <InvestorCard investor={t} onClose={onClose}/>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </ResultsWrap>
  )
}

export default ResultsBlock
