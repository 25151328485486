import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import breakpoints from "../../utils/breakpoints";
import Logolight from "../../assets/vectors/logo-latam-light.svg";
import Logodark from "../../assets/vectors/logo-latam-dark.svg";
import Hamburger from "../../assets/vectors/burger.svg";
import Close from "../../assets/vectors/close.svg";
import External from "../../assets/vectors/external.svg";
import IconSearch from "../../assets/vectors/icon-search.svg";
import CircleButton from '../../components/generic/circle_button/circle_button'
import Search from '../../components/search';

const scrollOffset = 100;

const Container = styled.header`
  position: fixed;
  z-index: var(--z-nav);
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem calc(var(--grid-padding) * 0.75);
  background-color: ${props =>
    props.mobiletheme === "light" || props.isscrolled === "true" ? "var(--brand-white)" : "transparent"};
  color: #333333;
  transition: background-color 0.2s var(--ease-soft);

  @media ${breakpoints.tabletLgSm} {
    padding: 2rem calc(var(--grid-padding));
  }

  @media ${breakpoints.tabletLg} {
    padding: 3.4rem calc(var(--grid-padding));
    background-color: ${props =>
      props.theme === "light" || props.isscrolled === "true" ? "var(--brand-white)" : "transparent"};
  }

  @media ${breakpoints.tabletLg} {
    transition: background-color 0.2s
        ${props => (props.isscrolled === "true" ? "0.4s" : "0s")}
        var(--ease-soft),
      transform 0.2s ${props => (props.isscrolled === "true" ? "0.4s" : "0s")}
        var(--ease-soft);

    &.is-hidden {
      transform: ${props =>
        props.theme === "light" ? "translateY(-150%)" : "none"};
    }

    &.is-visible {
      transform: none;
    }
  }

  @media ${breakpoints.desktop} {
    padding-left: calc(var(--grid-padding) * 1.83);
    padding-right: calc(var(--grid-padding) * 1.83);
  }

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;

    @media ${breakpoints.tabletLg} {
      display: flex;
      align-items: flex-start;
      margin-left: auto;
    }
  }

  li + li {
    margin-left: 2rem;
    @media ${breakpoints.tabletLg} {
      margin-left: 2.5rem;
    }
    @media ${breakpoints.desktopSm} {
      margin-left: 4rem;
    }
    @media ${breakpoints.desktop} {
      margin-left: 5rem;
    }
  }
`;

const PrimaryLink = styled.a`
  transition: color 0.2s
    ${props => (props.isscrolled === "true" ? "0.4s" : "0s")} var(--ease-soft);
  color: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "var(--brand-gray)" : "#FFF")};
  display: flex;
  align-items: flex-start;
  line-height: 1.2105263158;
  font-weight: 200;
  font-size: 2.6rem; 
  &.active,
  &:hover {
    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: ${props => (props.theme === "light" || props.isscrolled === "true" ? "var(--brand-gray)" : "#FFF")};
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.8rem;
    color: ${props => (props.theme === "light" || props.isscrolled === "true" ? "var(--brand-gray)" : "#FFF")};
  }

  svg {
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    stroke: currentColor;
    position: relative;
    top: 0.1rem;
    left: 0.4rem;
  }
  @media ${breakpoints.tabletLgSm} {
    font-size: 1.6rem; 
  }
  @media ${breakpoints.tabletLg} {
    font-size: 1.8rem; 
    color: ${props => (props.theme === "light" || props.isscrolled === "true" ? "var(--brand-gray)" : "#FFF")};
  }
  @media ${breakpoints.desktopSm} {
    font-size: 2.0rem; 
  }
  @media ${breakpoints.desktop} {
    font-size: 2.2rem; 
  }
  @media ${breakpoints.monitor} {
    font-size: 2.6rem; 
  }
`;

const LogoMobile = styled(Link)`
  display: flex;
  align-items: center;
  width: 14.2rem;
  height: 4.6rem;
  will-change: transform;
  visibility: visible;

  @media ${breakpoints.tabletLg} {
    display: none;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
  .light {
    display: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "none" : "block")};
  }
  .dark {
    display: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "block" : "none")};
  }
`;

const LogoDesktop = styled(Link)`
  display: none;
  position: relative;
  top: 0;
  width: 19.6rem;
  height: 2.1rem;
  opacity: 1;
  transition: opacity 0.2s var(--ease-soft);
  transition-delay: ${props => (props.isscrolled === "true" ? "0s" : "0.2s")};

  .light {
    display: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "none" : "block")};
  }
  .dark {
    display: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "block" : "none")};
  }

  @media ${breakpoints.tabletLg} {
    display: block;
    .light {
      display: ${props => (props.theme === "light" || props.isscrolled === "true" ? "none" : "block")};
    }
    .dark {
      display: ${props => (props.theme === "light" || props.isscrolled === "true" ? "block" : "none")};
    }
  }
  @media ${breakpoints.desktop} {
    width: 18rem;
  }
  @media ${breakpoints.monitor} {
    width: 19rem;
  }

  svg {
    position:relative;
    display: block;
    width: 100%;
    height: auto;
    top:50%;
    transform: translateY(-50%);
  }
`;

const Expanded = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-overlay);
  background-color: var(--brand-gray);
  color: #fff;
  visibility: ${props => (props.active ? "visible" : "hidden")};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: ${props =>
    props.active
      ? "opacity 0.75s var(--ease-soft)"
      : "opacity 0.75s var(--ease-soft), visibility 0s 0.75s linear"};
  padding: 0 calc(var(--grid-padding) * 0.75) 0;
  overflow:auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoints.tabletLgSm} {
    padding: 0 var(--grid-padding);
  }

  @media ${breakpoints.desktop} {
    padding: 0 calc(var(--grid-padding) * 1.83);
  }

  header {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 0;
    margin-bottom: 5rem;

    @media ${breakpoints.tabletLgSm} {
      margin-bottom: 0rem;
      padding: 2rem 0 2rem 0;
      ${LogoDesktop} {
        display:none;
      }
    }

    svg {
      display: block;
    }
  }

  ul.nav {
    display:block;
    margin: 0;
    padding: 4rem 0 0 0;
    list-style: none;

    ul {
      list-style: none;
      padding: 0;
      margin-top: 5rem;
    }

    @media ${breakpoints.tabletLg} {
      display:none;
    }
  }

  li {
    margin-bottom: 0.8rem;
    padding: 0;
    opacity: 0;
    transform: translateY(0.4rem);

    &.is-active {
      opacity: 1;
      transform: none;
      transition: opacity 0.5s var(--ease-soft), transform 0.5s var(--ease-soft);
    }
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

const ExpandedPrimaryLink = styled(Link)`
  display: inline-flex;
  font-size: 3.2rem;
  line-height: 1.125;
  font-family: var(--font-secondary);
  color: #fff;

  &:hover {
    color: #fff;
  }

  svg {
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    stroke: currentColor;
    position: relative;
    top: 0.6rem;
    left: 0.7rem;
  }
`;

const SearchSelector = styled.ul`
  display: inline-flex;
  height: 100%;
  margin: 0 0 0 6rem!important;
  padding:0;
  list-style: none;
  li {
    position: relative;
    padding: 0.4rem 1rem 0 1rem;
    margin: 0!important;
    align-self: stretch;
    cursor: pointer;
    svg {
      width: 2rem;
      height: 2rem;
      position:relative;
      display: block;
      top:50%;
      transform: translateY(-50%);
      path {
        fill: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "#000" : "#FFF")};
        @media ${breakpoints.tabletLg} {
          fill: ${props => (props.theme === "light" || props.isscrolled === "true" ? "#000" : "#FFF")};
        }
      }
    } 
     
  }
`;

const LangSelector = styled.ul`
  display: inline-flex;
  margin: 0 0 0 1rem!important;
  padding:0;
  list-style: none;
  li {
    position: relative;
    padding: 0 1rem;
    margin: 0!important;
    color: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "#807E7E" : "#FFF")};
    cursor: pointer;
    font-size: 1.8rem; 
    &:after {
      content: '';
      position: absolute;
      right:0;
      height: 100%;
      border: 1px solid rgba(51, 51, 51, 0.08);
    }   
    &.active {
      color: ${props => (props.theme === "light" || props.isscrolled === "true" ? "#333333" : "#FFF")};
    }
    &:last-child {
      &:after {
        display:none;
      } 
    }
    @media ${breakpoints.tabletLg} {
      color: ${props => (props.theme === "light" || props.isscrolled === "true" ? "#807E7E" : "#FFF")};
    }
  }
`;

const LangSelectorMobile = styled.ul`
  display: inline-flex;
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    position: relative;
    padding: 0 1rem;
    margin: 0!important;
    color: var(--brand-medium-gray);
    cursor: pointer;
    font-size: 1.6rem; 
    opacity:1;
    transform: none;
    align-items: center;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      right:0;
      height: 100%;
      border: 1px solid var(--brand-medium-gray);
      opacity: 0.08;
    }   
    &.active {
      color: #FFF;
      font-weight: 400;
    }
    &:last-child {
      &:after {
        display:none;
      } 
    }
    @media ${breakpoints.tabletLg} {
      display: none;
    }
  }
`;

const ExpandedCopyright = styled.p`
  margin: auto 0 2.5rem;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--brand-medium-gray);

  @media ${breakpoints.tabletLg} {
    margin-bottom: 5rem;
    display:none;
  }
`;

const ToggleOpen = styled.button`
  display: block;
  width: 3rem;
  height: 1.3rem;
  margin: auto 0 auto auto;
  padding: 0;

  @media ${breakpoints.tabletLg} {
    display: none;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    rect {
      fill: ${props =>
        props.mobiletheme === "light" || props.isscrolled === "true"
          ? "var(--brand-gray)"
          : "#FFF"};
    }
    stroke: ${props =>
      props.mobiletheme === "light" || props.isscrolled === "true"
        ? "var(--brand-gray)"
        : "#FFF"};
  }
`;

const HamburgerWrapper = styled(Hamburger)`
  display: block;
  width: 100%;
  height: 100%;
  stroke: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "#fff" : "#000")};
  transition: stroke 0.2s var(--ease-soft);
  @media ${breakpoints.tabletLg} {
    stroke: ${props => (props.theme === "light" || props.isscrolled === "true" ? "#fff" : "#000")};
  }
`;

const ToggleClose = styled.button`
  display: block;
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 0.5rem;
  margin-left: auto;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    stroke: #fff;
    @media ${breakpoints.tabletLgSm} {
      display: none!important;
    }
  }
  span {
    display: none;
    svg{
      stroke: initial;
      @media ${breakpoints.tabletLgSm} {
        display: inline-block!important;
      }
    }
    @media ${breakpoints.tabletLgSm} {
      display: block;
    }
  }
  @media ${breakpoints.tabletLgSm} {
    width: 6rem;
    height: 6rem;
  }
`;

const GradientBar = styled.div`
  position: absolute;
  width: 100%;
  height: 0.2rem;
  left: 0px;
  top:0;
  bottom: auto;
  background: linear-gradient(90deg, #E91711 5.69%, #EFD608 106.72%);
  display: ${props => (props.mobiletheme === "light" || props.isscrolled === "true" ? "block" : "none")};
  
  @media ${breakpoints.tabletLg} {
    top:auto;
    bottom: 0px;
    height: .389rem;
    display: ${props => (props.theme === "light" || props.isscrolled === "true" ? "block" : "none")};
  }
`;

export default function Header({ location, theme, mobileTheme, primaryLinks, themelogo, seo, nav, search, team, portfolio_companies }) {
  const data = useStaticQuery(graphql`
    query nav {
      cms {
        nav(handle: "main_nav") {
          tree {
            page {
              title
              url
            }
            children {
              page {
                title
                url
              }
            }
          }
        }
      }
    }
  `);
  const main_nav = nav?.tree || data.cms.nav.tree;
  const activeSite = seo?.translations?.filter(t => t.is_current)
  const [Lang, setLang] = useState(!activeSite || activeSite[0]?.site === 'default' ? 'en' : activeSite[0]?.site);
  const [hiddenClass, setHiddenClass] = useState("is-visible");
  const [isActive, setIsActive] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    setIsDesktop(window.innerWidth >= 1024)
    window.addEventListener('resize', () => {
      setIsDesktop(window.innerWidth >= 1024)
    })

    const onScroll = e => {
      let offset = theme === "light" ? scrollOffset : 1;

      if (window.scrollY >= offset) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const delay = theme === "light" ? 1500 : 0;

    setTimeout(() => {
      setHiddenClass("is-visible");
    }, delay);

    window.addEventListener("scroll", onScroll);
    setTimeout(() => {
      onScroll();
    }, 150);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [theme, mobileTheme, themelogo]);

  const handleClose = e => {
    setIsActive(false)
  };

  const getHomeLink = () => {
    let url = '/'
    switch(Lang) {
      case 'en':
        url = '/'
        break;
      case 'pt':
        url = '/pt/'
        break;
      case 'es':
        url = '/es/'
        break;
    }
    return url
  }

  return (
    <>
      <Helmet>
        {seo?.translations.map((item, index) => {
            if(item.exists) {
                let hreflang;
                switch(item.site) {
                    case 'default':
                        hreflang = 'x-default';
                        break;
                    default:
                        hreflang = item.site;
                }
                return <link rel="alternate" hreflang={hreflang} href={'https://www.latinamericafund.com' + item.uri} key={index} />;
            }
            return null;
        })}
      </Helmet>
      <Container
        id="header"
        isscrolled={`${isScrolled}`}
        className={hiddenClass}
        theme={theme}
        mobiletheme={mobileTheme}
      >
        <LogoMobile
          to={getHomeLink()}
          onClick={() => {setIsActive(false);setIsSearchActive(false);}}
          theme={theme}
          mobiletheme={mobileTheme}
          themelogo={themelogo}
          isscrolled={`${isScrolled}`}
        >
          <Logodark className="dark" />
          <Logolight className="light" />
        </LogoMobile>
        <LogoDesktop to={getHomeLink()} theme={theme} mobiletheme={mobileTheme} themelogo={themelogo} isscrolled={`${isScrolled}`}>
          <Logodark className="dark" />
          <Logolight className="light" />
        </LogoDesktop>
        <ToggleOpen
          onClick={() => setIsActive(true)}
          isscrolled={`${isScrolled}`}
          theme={themelogo || theme}
          mobiletheme={mobileTheme}
        >
          <HamburgerWrapper isscrolled={`${isScrolled}`} />
        </ToggleOpen>
        <ul className="main-nav">
          {main_nav.map((link, i) => {
            return (
              <li key={i}>
                <PrimaryLink
                  key={i}
                  isscrolled={`${isScrolled}`}
                  to={!link.page.external && !link.page.url.includes('http') ? link.page.url : ""}
                  href={link.page.external || link.page.url.includes('http') ? link.page.url : ""}
                  target={link.page.external ? "_blank" : "_self"}
                  rel={link.page.external ? "noreferrer" : "false"}
                  theme={theme}
                  mobiletheme={mobileTheme}
                  as={link.page.external ? "a" : Link}
                  className={`${location.pathname ===  link.page.url ? "active" : ""}`}
                >
                  <span>{link.page.title}</span>
                  {link.page.external && (
                    <span>
                      <External />
                    </span>
                  )}
                </PrimaryLink>
              </li>
            );
          })}
        </ul>
        <SearchSelector theme={theme} mobiletheme={mobileTheme}>
          <li onClick={() => setIsActive(true)}><IconSearch /></li>
        </SearchSelector>
        <LangSelector theme={theme} mobiletheme={mobileTheme}>
          <li className={`${Lang==='en' ? "active" : ""}`}>
            <Link to={seo?.translations?.find(t => t.site === 'default')?.uri}>
              EN
            </Link>
          </li>
          <li className={`${Lang==='es' ? "active" : ""}`}>
            <Link to={seo?.translations?.find(t => t.site === 'es')?.uri}>
              ES
            </Link>
          </li>
          <li className={`${Lang==='pt' ? "active" : ""}`}>
            <Link to={seo?.translations?.find(t => t.site === 'pt')?.uri}>
              PT
            </Link>
          </li>
        </LangSelector>
        <GradientBar theme={theme} mobiletheme={mobileTheme}/>
      </Container>
      <div className="test">
        <Expanded active={isActive}>
          <header>
            <LangSelectorMobile theme={theme} mobiletheme={mobileTheme}>
              <li className={`${Lang==='en' ? "active" : ""}`}>
                <Link to={seo?.translations?.find(t => t.site === 'default')?.uri}>
                  EN
                </Link>
              </li>
              <li className={`${Lang==='es' ? "active" : ""}`}>
                <Link to={seo?.translations?.find(t => t.site === 'es')?.uri}>
                  ES
                </Link>
              </li>
              <li className={`${Lang==='pt' ? "active" : ""}`}>
                <Link to={seo?.translations?.find(t => t.site === 'pt')?.uri}>
                  PT
                </Link>
              </li>
            </LangSelectorMobile>
            <ToggleClose
              onClick={() => {setIsActive(false);setIsSearchActive(false);}}
              isscrolled={`${isScrolled}`}
            >
              <Close />
              <CircleButton name="close" theme="outline-dark" size="medium" />
            </ToggleClose>
          </header>
          {isActive && <Search seo={seo} search={search} team={team} portfolio_companies={portfolio_companies} onActive={() => setIsSearchActive(true)} onClose={handleClose} />}
          {!isSearchActive && (
            <ul className={`nav`}>
              {main_nav.map((link, i) => (
                <li
                  key={i}
                  className={isActive ? "is-active" : ""}
                  style={{ transitionDelay: `${0.4 + i * 0.08}s` }}
                >
                  <ExpandedPrimaryLink
                    to={!link.page.external ? link.page.url : ""}
                    href={link.page.external ? link.page.url : ""}
                    target={link.page.external ? "_blank" : "_self"}
                    rel={link.page.external ? "noreferrer" : "false"}
                    onClick={() => {setIsActive(false);setIsSearchActive(false);}}
                  >
                    <span>{link.page.title}</span>
                    {link.page.external && (
                      <span>
                        <External />
                      </span>
                    )}
                  </ExpandedPrimaryLink>
                </li>
              ))}
            </ul>
          )}
          <ExpandedCopyright>
            &copy; {new Date().getFullYear()} SoftBank Group
          </ExpandedCopyright>
        </Expanded>
      </div>
    </>
  );
}
